// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: false,
  firebase : {
    apiKey: "AIzaSyAPAutWLcKHFXScxs3YU0igSAUKdI_77uQ",
    authDomain: "finance-monitor-5d0ee.firebaseapp.com",
    databaseURL: "https://finance-monitor-5d0ee.firebaseio.com",
    projectId: "finance-monitor-5d0ee",
    storageBucket: "",
    messagingSenderId: "472682610115",
    appId: "1:472682610115:web:af7d33f462fb9ee5241176",
    production: false
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
